import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeLayout"] */ "/app/src/components/theme-layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/contexts/progress-bar-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/contexts/query-client-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/contexts/session-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/contexts/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastProvider"] */ "/app/src/contexts/toast-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserPermissionProvider"] */ "/app/src/contexts/user-permission-context/user-permission-context.tsx");
