import React from "react";
import { Alert, Snackbar } from "@mui/material";

export type TToastState = {
  isOpen: boolean;
  message: string;
  isSuccess: boolean;
};

export const Toast = ({
  toastState,
  setToastState
}: {
  toastState: TToastState;
  setToastState: (toastState: TToastState) => void;
}): React.ReactNode => {
  const { isOpen, message, isSuccess } = toastState;

  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setToastState({
      ...toastState,
      isOpen: false
    });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={isOpen}
      autoHideDuration={3000}
    >
      <Alert onClose={handleClose} severity={isSuccess ? "success" : "error"} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};
