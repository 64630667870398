"use client";
import { createContext, useContext, useEffect, useState } from "react";
import { TToastState, Toast } from "@components/ui/toast";

export type TToastContext = {
  toastState: TToastState;
  setToastState: (toastState: TToastState) => void;
};
const ToastContext = createContext<TToastContext>({
  toastState: {
    message: "",
    isSuccess: true,
    isOpen: false
  },
  setToastState: () => {}
});

export const initialToastState: TToastState = {
  message: "",
  isSuccess: true,
  isOpen: false
};

export const ToastProvider = ({ children }: { children: React.ReactNode }): React.ReactNode => {
  const [toastState, setToastState] = useState(initialToastState);

  useEffect(() => {
    let toastTimeout: NodeJS.Timeout;

    if (toastState.isOpen) {
      toastTimeout = setTimeout(() => {
        setToastState({
          ...toastState,
          isOpen: false
        });
      }, 3000);
    }

    return () => {
      clearTimeout(toastTimeout);
    };
  }, [toastState]);

  return (
    <ToastContext.Provider value={{ toastState, setToastState }}>
      {children}
      <Toast toastState={toastState} setToastState={setToastState} />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
