"use client";
import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material/styles";
import { Roboto } from "next/font/google";
import React from "react";
import { blueGrey } from "@mui/material/colors";

const roboto = Roboto({
  weight: ["400", "700"],
  style: ["normal"],
  subsets: ["latin"]
});

const theme = createTheme({
  typography: {
    fontFamily: roboto.style.fontFamily
  },
  // match breakpoints with tailwindcss
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280
    }
  },
  palette: {
    primary: {
      main: "#1565C0"
    },
    secondary: {
      main: "#777777"
    },
    grey: {
      300: "#E0E0E0"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "uppercase",
          fontWeight: "normal"
        },
        containedPrimary: {
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#1565C0",
          color: "#FFF",
          textTransform: "capitalize",
          whitespace: "nowrap",
          width: "auto",
          borderRadius: "8px",
          textWrap: "nowrap",
          "&:hover": {
            backgroundColor: "#135BA1"
          },
          "&:active": {
            backgroundColor: "#0D3F73"
          }
        },
        outlinedSecondary: {
          border: `1px solid #E0E0E0`,
          backgroundColor: "#FFF",
          color: "#607D8B",
          borderRadius: "8px",
          justifyContent: "center",
          alignItems: "center",
          textTransform: "capitalize",
          whitespace: "nowrap",
          width: "auto",
          fontWeight: "500",
          textWrap: "nowrap",
          "&:hover": {
            backgroundColor: "#F5F5F5"
          },
          "&:active": {
            backgroundColor: "#E0E0E0"
          },
          "&:focus": {
            outline: "2px solid #E0E0E0"
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: () => ({
          "&.Mui-selected": {
            backgroundColor: blueGrey[50] + " !important"
          }
        })
      }
    },
    MuiTextField: {
      styleOverrides: {}
    },
    MuiSelect: {
      styleOverrides: {},
      defaultProps: {
        variant: "outlined",
        MenuProps: {
          disableScrollLock: true,
          PaperProps: {
            style: {
              maxHeight: 300
            }
          },
          transitionDuration: 0
        }
      }
    }
  }
});

const ThemeProvider = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
