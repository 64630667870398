import type { Session } from "next-auth";

class PermissionCheck {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  check({ session }) {
    throw new Error(`Subclass must implement the 'check()' method: ${this.constructor.name}`);
  }
}

class DashboardPermissionCheck extends PermissionCheck {
  check() {
    // setting true so the existing check is not broken
    return true;
  }
}

class HomePagePermissionCheck extends PermissionCheck {
  check() {
    // setting true so the existing check is not broken
    return true;
  }
}

class MonitoringPermissionCheck extends PermissionCheck {
  check({ session }: { session: Session }) {
    const isSuperuser = session?.user?.groups?.includes("user:superuser");

    if (isSuperuser || session?.user?.groups?.includes("user:monitoring")) {
      return true;
    }
    return false;
  }
}

class DueDiligencePermissionCheck extends PermissionCheck {
  check({ session }: { session: Session }) {
    if (session?.user?.groups?.includes("user:due_diligence")) {
      return true;
    }
    return false;
  }
}

class AdminPermissionCheck extends PermissionCheck {
  check({ session }: { session: Session }) {
    const isSuperuser = session?.user?.groups?.includes("user:superuser");

    if (isSuperuser) {
      return true;
    }
    return false;
  }
}

export {
  AdminPermissionCheck,
  DashboardPermissionCheck,
  HomePagePermissionCheck,
  PermissionCheck,
  MonitoringPermissionCheck,
  DueDiligencePermissionCheck
};
